@import url('../../fonts.css');

* {
    font-family: 'Space Grotesk', sans-serif;
    background-color: black;
    color: white;
}

h1 {
    font-family: 'Hanson';
    background: -webkit-linear-gradient(left, #EC7045 10%, #E857D2 90%);
    background-clip: text;
    /* Standard property */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h2 {
    margin-top: 40px;
}

p {
    color: lightgray
}

@media (min-width: 768px) {
    body {
        padding-left: 20vw;
        padding-right: 20vw;
        padding-top: 50px;
    }
}

@media (max-width: 767px) {
    body {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }
}