@import url('../../fonts.css');

.Home {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  /* Adjust the background color as needed */
  font-family: 'Space Grotesk', sans-serif;
  /* Default font */
}

.video-container {
  flex: 1;
  max-width: 30%;
  position: relative;
  display: flex;
  justify-content: end;
  /* For positioning the overlay content */
}

.video-container video {
  width: 285px;
  height: 620px;
  border: 1px solid #4F4F4F;
  border-radius: 32px;
}

.info-container {
  flex: 1;
  max-width: 30%;
  padding: 70px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
}

.by {
  font-size: 1em;
  /* Adjust size as needed */
  color: white;
  /* Light grey color, adjust as needed */
  max-width: 400px;
  margin-top: 70px
}

.profile-pic {
  width: 3.5em;
  height: 3.5em;
  margin-top: 30px;
}

.app-title {
  font-family: 'Hanson', sans-serif;
  font-size: 3em;
  /* Adjust size as needed */
  background: -webkit-linear-gradient(left, #EC7045 10%, #E857D2 90%);
  background-clip: text; /* Standard property */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Gradient fill color */
  margin-bottom: 0px;
}

.app-desc {
  font-size: 1em;
  /* Adjust size as needed */
  color: #EAEAEA;
  /* Light grey color, adjust as needed */
  margin-bottom: 2em;
  max-width: 400px;
}

.download-button {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 500;
  font-size: 1em;
  color: black;
  background-color: white;
  border: 1px solid;
  border-radius: 20px;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease;
  text-decoration: none;
  text-align: center;
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Center content horizontally */
  align-items: center; 
  width: 100%;
  width: 160px;
  height: 40px;
}

/* .download-button:hover {
  background-color: #aaa; 
} */

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .Home {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh
  }

  .video-container {
    align-items: center;
    max-width: 40%;
    min-width: 250px;
    height: auto;
    margin-top: 2em;
    margin-bottom: 3em
  }

  .video-container video {
    width: 100%;
    height: auto;
    border: 1px solid #4F4F4F;
    border-radius: 2em;
  }

  .info-container {
    width: 50%;
    order: -1;
    margin-top: 10vw;
    align-items: center;
  }

  .app-title {
    font-size: 2em;
  }

  .by {
    margin-top: 3em;
  }

  .profile-pic {
    margin-top: 1em;
  }

  .app-desc {
    width: 90vw;
    text-align: center;
    margin-top: 1em;
  }

  .info-container {
    padding: 20px;
  }
}

@media (min-width: 769px) {
  .Home {
    height: 100vh; /* This will now only apply for screens wider than 768px */
  }
}
