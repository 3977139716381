@font-face {
    font-family: 'Hanson';
    src: url('fonts/Hanson-Bold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    font-weight: 300;
    src: url('fonts/SpaceGrotesk-Light.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    font-weight: 400;
    src: url('fonts/SpaceGrotesk-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    font-weight: 500;
    src: url('fonts/SpaceGrotesk-Medium.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    font-weight: 600;
    src: url('fonts/SpaceGrotesk-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    font-weight: 700;
    src: url('fonts/SpaceGrotesk-Bold.ttf') format('truetype');
  }
  